@import '@style/theme.scss';

* {
    box-sizing: border-box;
}

html {
    margin: 0;
    height: 100%;
    scroll-behavior: smooth;
}
body {
    margin: 0;
    scroll-behavior: smooth;
    overscroll-behavior: contain;
    height: 100%;
    overflow-y: auto;
    min-height: 100vh;
    max-height: max-content;
    height: max-content;
    background: transparent;

    &.embed-dynamic {
        overflow: hidden;
    }
}

body.with-scrollbar {
    overflow-y: scroll;
}

#app {
    margin: 0;
    position: relative;
}

body .pac-container {
    // display: block;
    z-index: $z-index-loader + 1000;
} 