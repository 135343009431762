@font-face {
	font-family: 'Proxima Nova';
	src: local('ProximaNova-Regular'),
         url('../assets/fonts/proxima-nova-regular.woff2') format('woff2'),
         url('../assets/fonts/proxima-nova-regular.woff') format('woff'),
         url('../assets/fonts/proxima-nova-regular.otf') format('opentype');
	font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
	font-family: 'Proxima Nova';
	src: local('ProximaNova-Bold'),
         url('../assets/fonts/proxima-nova-bold.woff2') format('woff2'),
         url('../assets/fonts/proxima-nova-bold.woff') format('woff'),
         url('../assets/fonts/proxima-nova-bold.otf') format('opentype');
	font-weight: bold;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: local('Poppins Regular'),
         local('Poppins-Regular'),
         url('../assets/fonts/poppins.woff2') format('woff2'),
         url('../assets/fonts/poppins.woff') format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-display: block;
    src: local('Poppins Bold'),
         local('Poppins-Bold'),
         url('../assets/fonts/poppins-bold.woff2') format('woff2'),
         url('../assets/fonts/poppins-bold.woff') format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}